import React from 'react';
import ReactDOM from 'react-dom';
import cookies from '@colourbox/colourbox-cookies';
import { CookieWall } from '@colourbox/colourbox-cookies/ui/react';
import { callback } from './components/helpers/cookiewall.utils';

if (cookies.get('cookie_consent_necessary') === '1') {
    window.dataLayer = [];
}

// If PMG is embedded (some customers do this)
// We will not show the cookie wall and act as if they accepted cookies.
const queryString = window.location.search;
let isEmbed = false;
if (queryString) {
    const urlParams = new URLSearchParams(queryString);
    const embedded = urlParams.get('embed');
    if (embedded === 'true') {
        window.dataLayer = [];
        // like cookies.setConsentAll(); but with samesite=none and secure to allow it in iframes.
        document.cookie = 'cookie_consent_all=1; SameSite=None; Secure';
        isEmbed = true;
    }
}

if (!isEmbed) {
    ReactDOM.render(
        <CookieWall
            domain="skyfish"
            type="popup"
            language={window.GLOBALS.LOCALE.substring(0, 2)}
            onAcceptAll={() => callback()}
            onAcceptNecessary={() => {
                window.dataLayer = [];
            }}
        />,
        document.getElementById('cookiewall'),
    );
}
